html {
  height: 100%;
}

body {
	margin: 0;
  min-height: 100%;
}

#root {
	display: flex;
  flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

button
{
	display: inline-block;
	cursor: pointer;
	line-height: 1.5;
	font-size: .64rem;
	color: #fff;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	text-transform: uppercase;
	word-wrap: break-word;
	white-space: normal;
	margin: .375rem;
	padding: .5rem 1.6rem;
	border: 0;
	border-radius: .125rem;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
	background-color: #3f51b5;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button:hover
{
	box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
}
